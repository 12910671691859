<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/frontpage/frontpagelabellist')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
        <div class="form">
      <el-form ref="form" :model="form" label-width="180px">
          <el-form-item  :required="true" label="排序">
            <el-input onkeyup="value=value.replace(/[^\d]/g,'')" v-model="form.sort"></el-input>
          </el-form-item>
          <el-form-item :required="true" label="页面地址">
            <el-input v-model="form.url"></el-input>
          </el-form-item>
          <el-form-item :required="true" label="展示形式">
            <el-select v-model="form.type" placeholder="请选择">
                <el-option label="文字" :value="1"></el-option>
                <el-option label="图片" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item  :required="true" v-if="form.type == 1" label="名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item  :required="true" v-if="form.type == 1" label="英文名称">
            <el-input v-model="form.en_name"></el-input>
          </el-form-item>
           <el-image 
           v-if="form.images" 
            style="width: 100px;margin-left: 180px;"
            :src="form.images" 
            :preview-src-list="[form.images]"
            fit="contain">
          </el-image>
            <el-upload style=" margin-left: 180px;"
            v-if="form.type == 2"
                class="upload-demo"
                action="#"
                :http-request="httpRequest"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                multiple
                :limit="limit"
                :on-exceed="handleExceed"
                :before-upload="beforeAvatarUpload"
                :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2MB</div>
            </el-upload>
        <el-form-item>
          <el-button type="primary" v-if="!look" @click="onSubmit">确认</el-button>
        </el-form-item>
      </el-form>
    </div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
        look:false,
          sort:'',
          type:'',
          name: '',
          en_name:'',
          url: '',
          id:'',
          images:'',
          updated_at:'',
        },
        limit:1,
        options:[],
        fileList: []
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
      httpRequest(data) {
            let file = data.file;
            const isImg = file.size / 1024 / 1024 < 2
            if (!isImg) {
                 return 
            }
            const isType = file.type === "image/png"
            const isType2 = file.type === "image/jpeg"
            if (!isType && !isType2) {
                 return 
            }
          let _this = this;
        _this.form.name = file.name;
        let formdata = new FormData();
        formdata.append("file",file,file.name)
          axios.post(config.homeMenuUpload,formdata,{headers:{'Content-Type': 'multipart/form-data'}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.form.images = response.data.data[0].images;
              _this.$message({
                message: '成功',
                type: 'success'
              });
            }else{
              _this.$message.error(response.data.message)
            }
          })
        .catch(function (error) {
          _this.$message.error(error)
        });
        },
        beforeAvatarUpload(file) {
            const isImg = file.size / 1024 / 1024 < 2
            if (!isImg) {
                this.$message.error('上传头像图片大小不能超过 2MB!')
            }
                
            const isType = file.type === "image/png"
            const isType2 = file.type === "image/jpeg"

            if (!isType && !isType2) {
                this.$message.error('上传头像图片格式为png或jpg')
            }
            return (isType || isType2) && isImg
        },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
      handleExceed(files, fileList) {
          this.$message.warning('当前限制选择 '+this.limit+' 个文件，本次选择了 '+files.length+' 个文件，共选择了'+ (files.length + fileList.length)+' 个文件');

      },
     onSubmit() {
      let _this = this;
      let postdata = this.form;
      
      if(!postdata.sort){
        this.$alert("排序必填");
        return;
      }
      postdata.images = postdata.images?postdata.images:'';
      axios.post(config.homeMenu,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav('/frontpage/frontpagelabellist');
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
    init(a){
        let _this = this;
        let obj = JSON.parse(a);
        _this.look = obj.look;
        this.form = obj;
        axios.get(config.homeMenuInfo,{params:{id:obj.id}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.form = response.data.data
              _this.fileList.push({
                url:response.data.data.images
              })
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
  }
}
</script>
<style scoped>
.form{
    width: 500px;
    margin-left: 200px;
}
.el-icon-close:before{
  font-size: 24px;
}
</style>
